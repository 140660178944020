var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import RcSlider from 'rc-slider';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import warning from '../_util/warning';
import SliderTooltip from './SliderTooltip';
const Slider = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction, getPopupContainer } = React.useContext(ConfigContext);
    const [opens, setOpens] = React.useState({});
    const toggleTooltipOpen = (index, open) => {
        setOpens((prev) => (Object.assign(Object.assign({}, prev), { [index]: open })));
    };
    const getTooltipPlacement = (tooltipPlacement, vertical) => {
        if (tooltipPlacement) {
            return tooltipPlacement;
        }
        if (!vertical) {
            return 'top';
        }
        return direction === 'rtl' ? 'left' : 'right';
    };
    const { prefixCls: customizePrefixCls, range, className } = props, restProps = __rest(props, ["prefixCls", "range", "className"]);
    const prefixCls = getPrefixCls('slider', customizePrefixCls);
    const cls = classNames(className, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    });
    // make reverse default on rtl direction
    if (direction === 'rtl' && !restProps.vertical) {
        restProps.reverse = !restProps.reverse;
    }
    // Range config
    const [mergedRange, draggableTrack] = React.useMemo(() => {
        if (!range) {
            return [false];
        }
        return typeof range === 'object' ? [true, range.draggableTrack] : [true, false];
    }, [range]);
    // Warning for deprecated usage
    if (process.env.NODE_ENV !== 'production') {
        [
            ['tooltipPrefixCls', 'prefixCls'],
            ['getTooltipPopupContainer', 'getPopupContainer'],
            ['tipFormatter', 'formatter'],
            ['tooltipPlacement', 'placement'],
            ['tooltipVisible', 'open'],
        ].forEach(([deprecatedName, newName]) => {
            warning(!(deprecatedName in props), 'Slider', `\`${deprecatedName}\` is deprecated which will be removed in next major version, please use \`tooltip.${newName}\` instead.`);
        });
    }
    const handleRender = (node, info) => {
        var _a;
        const { index, dragging } = info;
        const rootPrefixCls = getPrefixCls();
        const { tooltip = {}, vertical } = props;
        const tooltipProps = Object.assign({ formatter: (_a = props.tipFormatter) !== null && _a !== void 0 ? _a : 
            // eslint-disable-next-line func-names
            function (value) {
                return typeof value === 'number' ? value.toString() : '';
            }, open: props.tooltipVisible, placement: props.tooltipPlacement, getPopupContainer: props.getTooltipPopupContainer }, tooltip);
        const { open: tooltipOpen, placement: tooltipPlacement, getPopupContainer: getTooltipPopupContainer, prefixCls: customizeTooltipPrefixCls, formatter: tipFormatter, } = tooltipProps;
        const isTipFormatter = tipFormatter ? opens[index] || dragging : false;
        const open = tooltipOpen || (tooltipOpen === undefined && isTipFormatter);
        const passedProps = Object.assign(Object.assign({}, node.props), { onMouseEnter: () => toggleTooltipOpen(index, true), onMouseLeave: () => toggleTooltipOpen(index, false) });
        const tooltipPrefixCls = getPrefixCls('tooltip', customizeTooltipPrefixCls);
        return (React.createElement(SliderTooltip, { prefixCls: tooltipPrefixCls, title: tipFormatter ? tipFormatter(info.value) : '', open: open, placement: getTooltipPlacement(tooltipPlacement, vertical), transitionName: `${rootPrefixCls}-zoom-down`, key: index, overlayClassName: `${prefixCls}-tooltip`, getPopupContainer: getTooltipPopupContainer || getPopupContainer }, React.cloneElement(node, passedProps)));
    };
    return (React.createElement(RcSlider, Object.assign({}, restProps, { step: restProps.step, range: mergedRange, draggableTrack: draggableTrack, className: cls, ref: ref, prefixCls: prefixCls, handleRender: handleRender })));
});
if (process.env.NODE_ENV !== 'production') {
    Slider.displayName = 'Slider';
}
export default Slider;
