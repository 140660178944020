var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import RcTooltip from 'rc-tooltip';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import { PresetColorTypes } from '../_util/colors';
import { getTransitionName } from '../_util/motion';
import getPlacements, { AdjustOverflow, PlacementsConfig } from '../_util/placements';
import { cloneElement, isValidElement, isFragment } from '../_util/reactNode';
import warning from '../_util/warning';
export { AdjustOverflow, PlacementsConfig };
const splitObject = (obj, keys) => {
    const picked = {};
    const omitted = Object.assign({}, obj);
    keys.forEach(key => {
        if (obj && key in obj) {
            picked[key] = obj[key];
            delete omitted[key];
        }
    });
    return { picked, omitted };
};
const PresetColorRegex = new RegExp(`^(${PresetColorTypes.join('|')})(-inverse)?$`);
// Fix Tooltip won't hide at disabled button
// mouse events don't trigger at disabled button in Chrome
// https://github.com/react-component/tooltip/issues/18
function getDisabledCompatibleChildren(element, prefixCls) {
    const elementType = element.type;
    if (((elementType.__ANT_BUTTON === true || element.type === 'button') && element.props.disabled) ||
        (elementType.__ANT_SWITCH === true && (element.props.disabled || element.props.loading)) ||
        (elementType.__ANT_RADIO === true && element.props.disabled)) {
        // Pick some layout related style properties up to span
        // Prevent layout bugs like https://github.com/ant-design/ant-design/issues/5254
        const { picked, omitted } = splitObject(element.props.style, [
            'position',
            'left',
            'right',
            'top',
            'bottom',
            'float',
            'display',
            'zIndex',
        ]);
        const spanStyle = Object.assign(Object.assign({ display: 'inline-block' }, picked), { cursor: 'not-allowed', width: element.props.block ? '100%' : undefined });
        const buttonStyle = Object.assign(Object.assign({}, omitted), { pointerEvents: 'none' });
        const child = cloneElement(element, {
            style: buttonStyle,
            className: null,
        });
        return (React.createElement("span", { style: spanStyle, className: classNames(element.props.className, `${prefixCls}-disabled-compatible-wrapper`) }, child));
    }
    return element;
}
const Tooltip = React.forwardRef((props, ref) => {
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, direction, } = React.useContext(ConfigContext);
    // Warning for deprecated usage
    if (process.env.NODE_ENV !== 'production') {
        [
            ['visible', 'open'],
            ['defaultVisible', 'defaultOpen'],
            ['onVisibleChange', 'onOpenChange'],
            ['afterVisibleChange', 'afterOpenChange'],
        ].forEach(([deprecatedName, newName]) => {
            warning(!(deprecatedName in props), 'Tooltip', `\`${deprecatedName}\` is deprecated which will be removed in next major version, please use \`${newName}\` instead.`);
        });
    }
    const [open, setOpen] = useMergedState(false, {
        value: props.open !== undefined ? props.open : props.visible,
        defaultValue: props.defaultOpen !== undefined ? props.defaultOpen : props.defaultVisible,
    });
    const isNoTitle = () => {
        const { title, overlay } = props;
        return !title && !overlay && title !== 0; // overlay for old version compatibility
    };
    const onOpenChange = (vis) => {
        var _a, _b;
        setOpen(isNoTitle() ? false : vis);
        if (!isNoTitle()) {
            (_a = props.onOpenChange) === null || _a === void 0 ? void 0 : _a.call(props, vis);
            (_b = props.onVisibleChange) === null || _b === void 0 ? void 0 : _b.call(props, vis);
        }
    };
    const getTooltipPlacements = () => {
        const { builtinPlacements, arrowPointAtCenter = false, autoAdjustOverflow = true } = props;
        return (builtinPlacements ||
            getPlacements({
                arrowPointAtCenter,
                autoAdjustOverflow,
            }));
    };
    // 动态设置动画点
    const onPopupAlign = (domNode, align) => {
        const placements = getTooltipPlacements();
        // 当前返回的位置
        const placement = Object.keys(placements).find(key => {
            var _a, _b;
            return placements[key].points[0] === ((_a = align.points) === null || _a === void 0 ? void 0 : _a[0]) &&
                placements[key].points[1] === ((_b = align.points) === null || _b === void 0 ? void 0 : _b[1]);
        });
        if (!placement) {
            return;
        }
        // 根据当前坐标设置动画点
        const rect = domNode.getBoundingClientRect();
        const transformOrigin = { top: '50%', left: '50%' };
        if (/top|Bottom/.test(placement)) {
            transformOrigin.top = `${rect.height - align.offset[1]}px`;
        }
        else if (/Top|bottom/.test(placement)) {
            transformOrigin.top = `${-align.offset[1]}px`;
        }
        if (/left|Right/.test(placement)) {
            transformOrigin.left = `${rect.width - align.offset[0]}px`;
        }
        else if (/right|Left/.test(placement)) {
            transformOrigin.left = `${-align.offset[0]}px`;
        }
        domNode.style.transformOrigin = `${transformOrigin.left} ${transformOrigin.top}`;
    };
    const getOverlay = () => {
        const { title, overlay } = props;
        if (title === 0) {
            return title;
        }
        return overlay || title || '';
    };
    const { getPopupContainer, placement = 'top', mouseEnterDelay = 0.1, mouseLeaveDelay = 0.1 } = props, otherProps = __rest(props, ["getPopupContainer", "placement", "mouseEnterDelay", "mouseLeaveDelay"]);
    const { prefixCls: customizePrefixCls, openClassName, getTooltipContainer, overlayClassName, color, overlayInnerStyle, children, } = props;
    const prefixCls = getPrefixCls('tooltip', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    let tempOpen = open;
    // Hide tooltip when there is no title
    if (!('open' in props) && !('visible' in props) && isNoTitle()) {
        tempOpen = false;
    }
    const child = getDisabledCompatibleChildren(isValidElement(children) && !isFragment(children) ? children : React.createElement("span", null, children), prefixCls);
    const childProps = child.props;
    const childCls = !childProps.className || typeof childProps.className === 'string'
        ? classNames(childProps.className, {
            [openClassName || `${prefixCls}-open`]: true,
        })
        : childProps.className;
    const customOverlayClassName = classNames(overlayClassName, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-${color}`]: color && PresetColorRegex.test(color),
    });
    let formattedOverlayInnerStyle = overlayInnerStyle;
    let arrowContentStyle = {};
    if (color && !PresetColorRegex.test(color)) {
        formattedOverlayInnerStyle = Object.assign(Object.assign({}, overlayInnerStyle), { background: color });
        // @ts-ignore
        arrowContentStyle = { '--antd-arrow-background-color': color };
    }
    return (React.createElement(RcTooltip, Object.assign({}, otherProps, { placement: placement, mouseEnterDelay: mouseEnterDelay, mouseLeaveDelay: mouseLeaveDelay, prefixCls: prefixCls, overlayClassName: customOverlayClassName, getTooltipContainer: getPopupContainer || getTooltipContainer || getContextPopupContainer, ref: ref, builtinPlacements: getTooltipPlacements(), overlay: getOverlay(), visible: tempOpen, onVisibleChange: onOpenChange, onPopupAlign: onPopupAlign, overlayInnerStyle: formattedOverlayInnerStyle, arrowContent: React.createElement("span", { className: `${prefixCls}-arrow-content`, style: arrowContentStyle }), motion: {
            motionName: getTransitionName(rootPrefixCls, 'zoom-big-fast', props.transitionName),
            motionDeadline: 1000,
        } }), tempOpen ? cloneElement(child, { className: childCls }) : child));
});
if (process.env.NODE_ENV !== 'production') {
    Tooltip.displayName = 'Tooltip';
}
export default Tooltip;
