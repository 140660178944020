var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import { Item } from 'rc-menu';
import toArray from 'rc-util/lib/Children/toArray';
import * as React from 'react';
import { SiderContext } from '../layout/Sider';
import Tooltip from '../tooltip';
import { cloneElement, isValidElement } from '../_util/reactNode';
import MenuContext from './MenuContext';
export default class MenuItem extends React.Component {
    constructor() {
        super(...arguments);
        this.renderItem = ({ siderCollapsed }) => {
            var _a;
            const { prefixCls, firstLevel, inlineCollapsed, direction, disableMenuItemTitleTooltip } = this.context;
            const { className, children } = this.props;
            const _b = this.props, { title, icon, danger } = _b, rest = __rest(_b, ["title", "icon", "danger"]);
            let tooltipTitle = title;
            if (typeof title === 'undefined') {
                tooltipTitle = firstLevel ? children : '';
            }
            else if (title === false) {
                tooltipTitle = '';
            }
            const tooltipProps = {
                title: tooltipTitle,
            };
            if (!siderCollapsed && !inlineCollapsed) {
                tooltipProps.title = null;
                // Reset `open` to fix control mode tooltip display not correct
                // ref: https://github.com/ant-design/ant-design/issues/16742
                tooltipProps.open = false;
            }
            const childrenLength = toArray(children).length;
            let returnNode = (React.createElement(Item, Object.assign({}, rest, { className: classNames({
                    [`${prefixCls}-item-danger`]: danger,
                    [`${prefixCls}-item-only-child`]: (icon ? childrenLength + 1 : childrenLength) === 1,
                }, className), title: typeof title === 'string' ? title : undefined }),
                cloneElement(icon, {
                    className: classNames(isValidElement(icon) ? (_a = icon.props) === null || _a === void 0 ? void 0 : _a.className : '', `${prefixCls}-item-icon`),
                }),
                this.renderItemChildren(inlineCollapsed)));
            if (!disableMenuItemTitleTooltip) {
                returnNode = (React.createElement(Tooltip, Object.assign({}, tooltipProps, { placement: direction === 'rtl' ? 'left' : 'right', overlayClassName: `${prefixCls}-inline-collapsed-tooltip` }), returnNode));
            }
            return returnNode;
        };
    }
    renderItemChildren(inlineCollapsed) {
        const { prefixCls, firstLevel } = this.context;
        const { icon, children } = this.props;
        const wrapNode = React.createElement("span", { className: `${prefixCls}-title-content` }, children);
        // inline-collapsed.md demo 依赖 span 来隐藏文字,有 icon 属性，则内部包裹一个 span
        // ref: https://github.com/ant-design/ant-design/pull/23456
        if (!icon || (isValidElement(children) && children.type === 'span')) {
            if (children && inlineCollapsed && firstLevel && typeof children === 'string') {
                return React.createElement("div", { className: `${prefixCls}-inline-collapsed-noicon` }, children.charAt(0));
            }
        }
        return wrapNode;
    }
    render() {
        return React.createElement(SiderContext.Consumer, null, this.renderItem);
    }
}
MenuItem.contextType = MenuContext;
