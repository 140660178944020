var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import RcCheckbox from 'rc-checkbox';
import * as React from 'react';
import { useContext } from 'react';
import { ConfigContext } from '../config-provider';
import { FormItemInputContext } from '../form/context';
import warning from '../_util/warning';
import { GroupContext } from './Group';
import DisabledContext from '../config-provider/DisabledContext';
const InternalCheckbox = (_a, ref) => {
    var _b;
    var { prefixCls: customizePrefixCls, className, children, indeterminate = false, style, onMouseEnter, onMouseLeave, skipGroup = false, disabled } = _a, restProps = __rest(_a, ["prefixCls", "className", "children", "indeterminate", "style", "onMouseEnter", "onMouseLeave", "skipGroup", "disabled"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const checkboxGroup = React.useContext(GroupContext);
    const { isFormItemInput } = useContext(FormItemInputContext);
    const contextDisabled = useContext(DisabledContext);
    const mergedDisabled = (_b = ((checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.disabled) || disabled)) !== null && _b !== void 0 ? _b : contextDisabled;
    const prevValue = React.useRef(restProps.value);
    React.useEffect(() => {
        checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.registerValue(restProps.value);
        warning('checked' in restProps || !!checkboxGroup || !('value' in restProps), 'Checkbox', '`value` is not a valid prop, do you mean `checked`?');
    }, []);
    React.useEffect(() => {
        if (skipGroup) {
            return;
        }
        if (restProps.value !== prevValue.current) {
            checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.cancelValue(prevValue.current);
            checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.registerValue(restProps.value);
            prevValue.current = restProps.value;
        }
        return () => checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.cancelValue(restProps.value);
    }, [restProps.value]);
    const prefixCls = getPrefixCls('checkbox', customizePrefixCls);
    const checkboxProps = Object.assign({}, restProps);
    if (checkboxGroup && !skipGroup) {
        checkboxProps.onChange = (...args) => {
            if (restProps.onChange) {
                restProps.onChange(...args);
            }
            if (checkboxGroup.toggleOption) {
                checkboxGroup.toggleOption({ label: children, value: restProps.value });
            }
        };
        checkboxProps.name = checkboxGroup.name;
        checkboxProps.checked = checkboxGroup.value.includes(restProps.value);
    }
    const classString = classNames({
        [`${prefixCls}-wrapper`]: true,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-wrapper-checked`]: checkboxProps.checked,
        [`${prefixCls}-wrapper-disabled`]: mergedDisabled,
        [`${prefixCls}-wrapper-in-form-item`]: isFormItemInput,
    }, className);
    const checkboxClass = classNames({
        [`${prefixCls}-indeterminate`]: indeterminate,
    });
    const ariaChecked = indeterminate ? 'mixed' : undefined;
    return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    React.createElement("label", { className: classString, style: style, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        React.createElement(RcCheckbox, Object.assign({ "aria-checked": ariaChecked }, checkboxProps, { prefixCls: prefixCls, className: checkboxClass, disabled: mergedDisabled, ref: ref })),
        children !== undefined && React.createElement("span", null, children)));
};
const Checkbox = React.forwardRef(InternalCheckbox);
if (process.env.NODE_ENV !== 'production') {
    Checkbox.displayName = 'Checkbox';
}
export default Checkbox;
