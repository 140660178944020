var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import useFlexGapSupport from '../_util/hooks/useFlexGapSupport';
import ResponsiveObserve, { responsiveArray } from '../_util/responsiveObserve';
import { tuple } from '../_util/type';
import RowContext from './RowContext';
const RowAligns = tuple('top', 'middle', 'bottom', 'stretch');
const RowJustify = tuple('start', 'end', 'center', 'space-around', 'space-between', 'space-evenly');
function useMergePropByScreen(oriProp, screen) {
    const [prop, setProp] = React.useState(typeof oriProp === 'string' ? oriProp : '');
    const clacMergeAlignOrJustify = () => {
        if (typeof oriProp === 'string') {
            setProp(oriProp);
        }
        if (typeof oriProp !== 'object') {
            return;
        }
        for (let i = 0; i < responsiveArray.length; i++) {
            const breakpoint = responsiveArray[i];
            // if do not match, do nothing
            if (!screen[breakpoint])
                continue;
            const curVal = oriProp[breakpoint];
            if (curVal !== undefined) {
                setProp(curVal);
                return;
            }
        }
    };
    React.useEffect(() => {
        clacMergeAlignOrJustify();
    }, [JSON.stringify(oriProp), screen]);
    return prop;
}
const Row = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, justify, align, className, style, children, gutter = 0, wrap } = props, others = __rest(props, ["prefixCls", "justify", "align", "className", "style", "children", "gutter", "wrap"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const [screens, setScreens] = React.useState({
        xs: true,
        sm: true,
        md: true,
        lg: true,
        xl: true,
        xxl: true,
    });
    // to save screens info when responsiveObserve callback had been call
    const [curScreens, setCurScreens] = React.useState({
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
        xxl: false,
    });
    // ================================== calc reponsive data ==================================
    const mergeAlign = useMergePropByScreen(align, curScreens);
    const mergeJustify = useMergePropByScreen(justify, curScreens);
    const supportFlexGap = useFlexGapSupport();
    const gutterRef = React.useRef(gutter);
    // ================================== Effect ==================================
    React.useEffect(() => {
        const token = ResponsiveObserve.subscribe(screen => {
            setCurScreens(screen);
            const currentGutter = gutterRef.current || 0;
            if ((!Array.isArray(currentGutter) && typeof currentGutter === 'object') ||
                (Array.isArray(currentGutter) &&
                    (typeof currentGutter[0] === 'object' || typeof currentGutter[1] === 'object'))) {
                setScreens(screen);
            }
        });
        return () => ResponsiveObserve.unsubscribe(token);
    }, []);
    // ================================== Render ==================================
    const getGutter = () => {
        const results = [undefined, undefined];
        const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, undefined];
        normalizedGutter.forEach((g, index) => {
            if (typeof g === 'object') {
                for (let i = 0; i < responsiveArray.length; i++) {
                    const breakpoint = responsiveArray[i];
                    if (screens[breakpoint] && g[breakpoint] !== undefined) {
                        results[index] = g[breakpoint];
                        break;
                    }
                }
            }
            else {
                results[index] = g;
            }
        });
        return results;
    };
    const prefixCls = getPrefixCls('row', customizePrefixCls);
    const gutters = getGutter();
    const classes = classNames(prefixCls, {
        [`${prefixCls}-no-wrap`]: wrap === false,
        [`${prefixCls}-${mergeJustify}`]: mergeJustify,
        [`${prefixCls}-${mergeAlign}`]: mergeAlign,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    // Add gutter related style
    const rowStyle = {};
    const horizontalGutter = gutters[0] != null && gutters[0] > 0 ? gutters[0] / -2 : undefined;
    const verticalGutter = gutters[1] != null && gutters[1] > 0 ? gutters[1] / -2 : undefined;
    if (horizontalGutter) {
        rowStyle.marginLeft = horizontalGutter;
        rowStyle.marginRight = horizontalGutter;
    }
    if (supportFlexGap) {
        // Set gap direct if flex gap support
        [, rowStyle.rowGap] = gutters;
    }
    else if (verticalGutter) {
        rowStyle.marginTop = verticalGutter;
        rowStyle.marginBottom = verticalGutter;
    }
    // "gutters" is a new array in each rendering phase, it'll make 'React.useMemo' effectless.
    // So we deconstruct "gutters" variable here.
    const [gutterH, gutterV] = gutters;
    const rowContext = React.useMemo(() => ({ gutter: [gutterH, gutterV], wrap, supportFlexGap }), [gutterH, gutterV, wrap, supportFlexGap]);
    return (React.createElement(RowContext.Provider, { value: rowContext },
        React.createElement("div", Object.assign({}, others, { className: classes, style: Object.assign(Object.assign({}, rowStyle), style), ref: ref }), children)));
});
if (process.env.NODE_ENV !== 'production') {
    Row.displayName = 'Row';
}
export default Row;
