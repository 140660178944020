import { TreeNode } from 'rc-tree';
import TreePure from './Tree';
import DirectoryTree from './DirectoryTree';
export { EventDataNode } from 'rc-tree/lib/interface';
export { DirectoryTreeProps, ExpandAction as DirectoryTreeExpandAction } from './DirectoryTree';
export { AntdTreeNodeAttribute, AntTreeNode, AntTreeNodeCheckedEvent, AntTreeNodeExpandedEvent, AntTreeNodeMouseEvent, AntTreeNodeProps, AntTreeNodeSelectedEvent, TreeProps, } from './Tree';
const Tree = TreePure;
Tree.DirectoryTree = DirectoryTree;
Tree.TreeNode = TreeNode;
export default Tree;
