import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import WarningFilled from '@ant-design/icons/WarningFilled';
import classNames from 'classnames';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import warning from '../_util/warning';
import noFound from './noFound';
import serverError from './serverError';
import unauthorized from './unauthorized';
export const IconMap = {
    success: CheckCircleFilled,
    error: CloseCircleFilled,
    info: ExclamationCircleFilled,
    warning: WarningFilled,
};
export const ExceptionMap = {
    '404': noFound,
    '500': serverError,
    '403': unauthorized,
};
// ExceptionImageMap keys
const ExceptionStatus = Object.keys(ExceptionMap);
const Icon = ({ prefixCls, icon, status }) => {
    const className = classNames(`${prefixCls}-icon`);
    warning(!(typeof icon === 'string' && icon.length > 2), 'Result', `\`icon\` is using ReactNode instead of string naming in v4. Please check \`${icon}\` at https://ant.design/components/icon`);
    if (ExceptionStatus.includes(`${status}`)) {
        const SVGComponent = ExceptionMap[status];
        return (React.createElement("div", { className: `${className} ${prefixCls}-image` },
            React.createElement(SVGComponent, null)));
    }
    const iconNode = React.createElement(IconMap[status]);
    if (icon === null || icon === false) {
        return null;
    }
    return React.createElement("div", { className: className }, icon || iconNode);
};
const Extra = ({ prefixCls, extra }) => {
    if (!extra) {
        return null;
    }
    return React.createElement("div", { className: `${prefixCls}-extra` }, extra);
};
const Result = ({ prefixCls: customizePrefixCls, className: customizeClassName, subTitle, title, style, children, status = 'info', icon, extra, }) => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('result', customizePrefixCls);
    const className = classNames(prefixCls, `${prefixCls}-${status}`, customizeClassName, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    });
    return (React.createElement("div", { className: className, style: style },
        React.createElement(Icon, { prefixCls: prefixCls, status: status, icon: icon }),
        React.createElement("div", { className: `${prefixCls}-title` }, title),
        subTitle && React.createElement("div", { className: `${prefixCls}-subtitle` }, subTitle),
        React.createElement(Extra, { prefixCls: prefixCls, extra: extra }),
        children && React.createElement("div", { className: `${prefixCls}-content` }, children)));
};
Result.PRESENTED_IMAGE_403 = ExceptionMap['403'];
Result.PRESENTED_IMAGE_404 = ExceptionMap['404'];
Result.PRESENTED_IMAGE_500 = ExceptionMap['500'];
export default Result;
